import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'
import '../styles/global.scss'

Wordpress2016.overrideThemeStyles = () => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`
    },
    a: {
      color: 'var(--textLink)'
    },
    'a.anchor': {
      boxShadow: 'none'
    },
    'a.anchor svg[aria-hidden="true"]': {
      stroke: 'var(--textLink)'
    },
    hr: {
      background: 'var(--hr)'
    }
  }
}

const fonts = ['Avenir Next', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif']

Wordpress2016.headerFontFamily = fonts
Wordpress2016.bodyFontFamily = fonts
delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
