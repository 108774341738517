import { navigate } from 'gatsby'

const openLink = (url) => {
  if (window && window.open) {
    window.open(url, '_blank')
  }
}

const to = (e, location) => {
  e.preventDefault()
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
  navigate(location)
}

export { openLink, to }
