/**
 * Return a color of the day base on
 * Khmer colors
 */
const colors = new Map([
  ['sun', 'red-500'], // #d92605 ក្រហម
  ['mon', 'amber-500'], // #e59730​ ទឹកក្រូច
  ['tue', 'violet-800'], // #5f275e ស្វាយ
  ['wed', 'lime-500'], // #8fc347 ត្រួយចេក
  ['thu', 'emerald-500'], // #385f42 បៃតង
  ['fri', 'blue-500'], // #1b0e87​ ខៀវ
  ['sat', 'pink-900'] // #4b0136 ឈាមជ្រូក
])

export const getColorOfTheDay = () => {
  return colors.get(
    ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][new Date().getDay()]
  )
}
