import React from 'react'
import { FooterMd } from './footer-md'
import { FooterXs } from './footer-xs'

const Footer = () => {
  return (
    <footer
      className="flex flex-col justify-between items-center align-middle pt-4 md:pt-8 pb-8 md:pb-12 w-full"
      style={{
        backgroundColor: 'var(--bg-card)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
        boxShadow: '10px 10px 10px 10px rgba(0, 0, 0, 0.1)'
      }}
    >
      <FooterXs styleV="flex md:hidden" />
      <FooterMd styleV="hidden md:flex" />
    </footer>
  )
}

export default Footer
