import React from 'react'

const Singularity = () => {
  return (
    <div
      className={`w-full font-light text-xs italic text-center md:mt-10`}
      style={{
        color: 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out'
      }}
    >
      You've reached singularity, the beginning of time!
    </div>
  )
}

export default Singularity
