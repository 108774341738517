/**
 * Format Views Count
 */

export const formatViewCount = (views) => {
  if (!views) return views

  if (views < 1000) {
    return `${views}`
  } else if (views < 1000000) {
    const inK = views / 1000
    return `${(Math.round(inK * 100) / 100).toFixed(2)} K`
  } else {
    const inM = views / 1000000
    return `${(Math.round(inM * 100) / 100).toFixed(2)} M`
  }
}
