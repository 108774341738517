import React, { useState } from 'react'
import { Link } from 'gatsby'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { isActive, getColorOfTheDay } from '../../../utils'
import '../../../styles/navbar-title.scss'

const NavbarXs = ({ location, styleV }) => {
  const [menuActive, setMenuActive] = useState(false)
  const toggleMenu = (value) => {
    if (document && !value) {
      const elem = document.getElementById('show-menu-id')
      elem.classList.remove('show-menu')
      elem.classList.remove('flex')
      elem.classList.remove('pb-4')

      elem.classList.add('hide-menu')
    } else if (document) {
      const elem = document.getElementById('show-menu-id')
      elem.classList.remove('hidden')
      elem.classList.remove('hide-menu')

      elem.classList.add('flex')
      elem.classList.add('show-menu')
      elem.classList.add('pb-4')
    }
    setMenuActive(value)
  }

  return (
    <div
      className={`${styleV} items-center justify-between flex-wrap w-full max-w-screen-sm bg-${getColorOfTheDay()}`}
    >
      <div className="w-full flex flex-row justify-between items-center py-1 text-white">
        <Link
          className={`flex ml-4 justify-center items-center flex-shrink-0 shadow-none border py-0 px-1 rounded-md`}
          to="/"
        >
          <svg
            className={`fill-current h-6 w-4`}
            viewBox="0 0 578.57 491"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Daseveny Logo</title>
            <path
              d="M0 0l245.21 245.71L0 491h335.38l243.19-245.5L333.87 0H0z"
              data-name="Daseveny"
            />
          </svg>
        </Link>

        <Link
          className="inline md:hidden text-2xl font-bold shadow-none"
          to="/"
        >
          <span>ដាសេវេនី</span>
        </Link>

        <div className="mr-3 block md:hidden">
          <button
            className="flex items-center focus:text-white mr-1 focus:outline-none"
            onClick={() => toggleMenu(!menuActive)}
          >
            <svg
              className={`fill-current h-6 w-6 ${menuActive ? 'hidden' : ''}`}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg
              className={`fill-current h-6 w-6 ${menuActive ? '' : 'hidden'}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
      </div>

      <div
        id="show-menu-id"
        className={`hidden flex-grow flex-col items-start text-white text-md ml-8`}
      >
        <Link
          className={`flex text-md items-center mt-4 align-middle shadow-none`}
          to="/"
          style={{
            fontWeight: isActive(location, '/') ? 'bold' : 'normal'
          }}
        >
          ដើមទំព័រ
        </Link>
        <Link
          className={`flex text-md items-center mt-4 align-middle shadow-none`}
          to="/programmings/1"
          style={{
            fontWeight: isActive(location, '/programmings') ? 'bold' : 'normal'
          }}
        >
          ប្រូក្រាមីង
        </Link>
        <Link
          className={`flex text-md items-center mt-3 align-middle shadow-none`}
          to="/technologies/1"
          style={{
            fontWeight: isActive(location, '/technologies') ? 'bold' : 'normal'
          }}
        >
          បច្ចេកវិទ្យា
        </Link>
        <Link
          className={`flex text-md items-center mt-3 align-middle shadow-none`}
          to="/blogs/1"
          style={{
            fontWeight: isActive(location, '/blogs') ? 'bold' : 'normal'
          }}
        >
          ប្លក់
        </Link>
        <Link
          className={`flex text-md items-center mt-3 align-middle shadow-none`}
          to="/about"
          style={{
            fontWeight: isActive(location, '/about') ? 'bold' : 'normal'
          }}
        >
          អំពីដាសេវេនី
        </Link>

        <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <button
              className={`flex items-center mt-3 hover:text-red-400 align-middle focus:text-white focus:font-bold focus:outline-none`}
              onClick={() => {
                toggleTheme(theme === 'dark' ? 'light' : 'dark')
                setTimeout(() => {
                  if (window) {
                    window.location.reload()
                  }
                }, 0)
              }}
            >
              {theme !== 'dark' ? (
                <span className="md:hidden text-md">Dark Mode</span>
              ) : (
                <span className="md:hidden text-md">Light Mode</span>
              )}
            </button>
          )}
        </ThemeToggler>
      </div>
    </div>
  )
}

export { NavbarXs }
