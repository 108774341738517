/**
 * Date format utils
 */
import { MONTHS_IN_KHMER } from '../constants'

const getMonth = (month) => {
  const matched = MONTHS_IN_KHMER.filter(
    (khmerMonth) => khmerMonth.month === month
  )
  return matched[0].label
}

const getDateInKhmer = (date) => {
  const dateObj = new Date(date)
  return `${dateObj.getDate()} ${getMonth(
    dateObj.getMonth()
  )} ${dateObj.getFullYear()}`
}

export { getDateInKhmer }
