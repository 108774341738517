const MONTHS_IN_KHMER = [
  {
    month: 0,
    label: 'មករា'
  },
  {
    month: 1,
    label: 'កុម្ភៈ'
  },
  {
    month: 2,
    label: 'មីនា'
  },
  {
    month: 3,
    label: 'មេសា'
  },
  {
    month: 4,
    label: 'ឧសភា'
  },
  {
    month: 5,
    label: 'មិថុនា'
  },
  {
    month: 6,
    label: 'កក្កដា'
  },
  {
    month: 7,
    label: 'សីហា'
  },
  {
    month: 8,
    label: 'កញ្ញា'
  },
  {
    month: 9,
    label: 'តុលា'
  },
  {
    month: 10,
    label: 'វិច្ឆិកា'
  },
  {
    month: 11,
    label: 'ធ្នូ'
  }
]

export { MONTHS_IN_KHMER }
