/**
 * APP CONSTANTS
 */
const baseURL = 'https://www.daseveny.com'
const coverWhiteList = ['/']
const unsplashCollections = [
  // '1129594', // computer-code 95 photos
  // '1080712', // spacex 18 photos
  // '1111678', // cool-wallpapers 127 photos
  // '827743/landscape', // 766 photos
  // '427860/view-angle', // 118 photos
  // '1971015/simplicity', // 187 photos
  // '2203755/slices-of-sky', // 147 photos
  // '168902/abstract-architecture', // 176 photos
  // '162468/earth-from-above', // 1.5k photos
  // '1525589/roads', // 102 photos
  // '9681713', // cambodia 20 photos,
  // '4267053', // angkor-wat 10 photos
  // '4553563', // siem-reap 13 photos,
  // '7745304', // spacex 11 photos
  // '645605', // pattern 32 photos
  // '1447840', // nice white 16 photos
  // '1661673', // code 16 photos
  '14267958', // dvc rockets
  '94666023', // dvc techies
  '60074749', // dvc cool wallpapers
  '20852475' // dvc Cambodia
]

const colorSchemes = [
  {
    bg: 'bg-red-400',
    bd: 'border-red-400'
  },
  {
    bg: 'bg-green-400',
    bd: 'border-green-400'
  },
  {
    bg: 'bg-yellow-400',
    bd: 'border-yellow-400'
  },
  {
    bg: 'bg-orange-400',
    bd: 'border-orange-400'
  },
  {
    bg: 'bg-teal-400',
    bd: 'border-teal-400'
  },
  {
    bg: 'bg-blue-400',
    bd: 'border-blue-400'
  },
  {
    bg: 'bg-indigo-400',
    bd: 'border-indigo-400'
  },
  {
    bg: 'bg-purple-400',
    bd: 'border-purple-400'
  },
  {
    bg: 'bg-pink-400',
    bd: 'border-pink-400'
  },
  {
    bg: 'bg-rose-400',
    bd: 'border-rose-400'
  },
  {
    bg: 'bg-fuchsia-400',
    bd: 'border-fuchsia-400'
  },
  {
    bg: 'bg-violet-400',
    bd: 'border-violet-400'
  },
  {
    bg: 'bg-lightBlue-400',
    bd: 'border-lightBlue-400'
  },
  {
    bg: 'bg-cyan-400',
    bd: 'border-cyan-400'
  },
  {
    bg: 'bg-emerald-400',
    bd: 'border-emerald-400'
  },
  {
    bg: 'bg-lime-400',
    bd: 'border-lime-400'
  },
  {
    bg: 'bg-amber-400',
    bd: 'border-amber-400'
  }
]

const defaultImg =
  'https://images.unsplash.com/photo-1519241047957-be31d7379a5d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80utm_source=Daseveny&utm_medium=referral'

export {
  baseURL,
  coverWhiteList,
  unsplashCollections,
  colorSchemes,
  defaultImg
}
