import React, { useState } from 'react'
import { Link } from 'gatsby'
import '../../../styles/navbar-title.scss'

const NavbarMd = ({ location, styleV }) => {
  const [logoHovered, setLogoHovered] = useState(false)
  return (
    <div
      className={`${styleV} items-end justify-start flex-wrap w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg`}
    >
      <Link
        className={`flex mb-1 ml-5 justify-center items-center flex-shrink-0 shadow-none border-2 py-0 px-1 rounded-md`}
        to="/"
        onMouseEnter={() => setLogoHovered(true)}
        onMouseLeave={() => setLogoHovered(false)}
        style={{
          borderColor: logoHovered ? '#fc8181' : 'var(--logoColorMd)'
        }}
      >
        <svg
          className={`fill-current md:h-logo md:w-logo md:py-0 md:mr-0`}
          viewBox="-100 0 778.57 491"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            color: logoHovered ? '#fc8181' : 'var(--logoColorMd)'
          }}
        >
          <title>Daseveny Logo</title>
          <path
            d="M0 0l245.21 245.71L0 491h335.38l243.19-245.5L333.87 0H0z"
            data-name="Daseveny"
          />
        </svg>
      </Link>
    </div>
  )
}

export { NavbarMd }
