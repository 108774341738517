import React, { useState } from 'react'
import { Link } from 'gatsby'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { isActive } from '../../../utils'
import { moonPaths, sunPaths } from '../../../constants/headings/navbar'

const getNavItemStyles = ({ active, hovered }) => {
  let styles = {
    color: ''
  }
  if ((!active && hovered) || active) {
    styles.color = 'black'
  }
  return styles
}

const SubNavbar = ({ location }) => {
  const [techHovered, setTechHovered] = useState(false)
  const [homeHovered, setHomeHovered] = useState(false)
  const [proHovered, setProHovered] = useState(false)
  const [blogHovered, setBlogHovered] = useState(false)
  const [abtHovered, setAbtHovered] = useState(false)
  return (
    <nav
      className={`hidden md:flex shadow-md items-center justify-center flex-wrap px-10 py-2 md:py-3 w-full mb-2 bg-red-400`}
    >
      <div
        className={`flex items-center justify-between flex-wrap w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg`}
      >
        <div
          className="flex flex-col md:flex-row items-center text-lg mt-2 md:mt-0 ml-10 font-bold"
          style={{ color: 'white' }}
        >
          <Link
            className={`flex text-md items-center mt-6 align-middle shadow-none md:inline-block md:mt-0 md:mr-8`}
            to="/"
            style={{
              ...getNavItemStyles({
                active: isActive(location, '/'),
                hovered: homeHovered
              })
            }}
            onMouseEnter={() => setHomeHovered(true)}
            onMouseLeave={() => setHomeHovered(false)}
          >
            ដើមទំព័រ
          </Link>
          <Link
            className={`flex text-md items-center mt-6 align-middle shadow-none md:inline-block md:mt-0 md:mr-8`}
            to="/programmings/1"
            style={{
              ...getNavItemStyles({
                active: isActive(location, '/programmings'),
                hovered: proHovered
              })
            }}
            onMouseEnter={() => setProHovered(true)}
            onMouseLeave={() => setProHovered(false)}
          >
            ប្រូក្រាមីង
          </Link>
          <Link
            className={`flex text-md items-center mt-6 align-middle shadow-none md:inline-block md:mt-0 md:mr-8`}
            to="/technologies/1"
            style={{
              ...getNavItemStyles({
                active: isActive(location, '/technologies'),
                hovered: techHovered
              })
            }}
            onMouseEnter={() => setTechHovered(true)}
            onMouseLeave={() => setTechHovered(false)}
          >
            បច្ចេកវិទ្យា
          </Link>
          <Link
            className={`flex text-md items-center mt-6 align-middle shadow-none md:inline-block md:mt-0 md:mr-8`}
            to="/blogs/1"
            style={{
              ...getNavItemStyles({
                active: isActive(location, '/blogs'),
                hovered: blogHovered
              })
            }}
            onMouseEnter={() => setBlogHovered(true)}
            onMouseLeave={() => setBlogHovered(false)}
          >
            ប្លក់
          </Link>
          <Link
            className={`flex text-md items-center mt-4 align-middle shadow-none md:inline-block md:mt-0 md:mr-8`}
            to="/about"
            style={{
              ...getNavItemStyles({
                active: isActive(location, '/about'),
                hovered: abtHovered
              })
            }}
            onMouseEnter={() => setAbtHovered(true)}
            onMouseLeave={() => setAbtHovered(false)}
          >
            អំពីដាសេវេនី
          </Link>
        </div>

        <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <button
              className={`flex items-center lg:inline-block hover:text-red-400 align-middle focus:text-white focus:font-bold focus:outline-none mt-5 md:mt-0 lg:mt-0 md:ml-0 mr-10`}
              onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}
            >
              {theme !== 'dark' ? (
                <svg
                  className={`hidden md:block fill-current w-8 h-8 text-white focus:text-white active:text-white hover:text-gray-400`}
                  viewBox="0 0 400 429.71"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Dark Button Day</title>
                  <g fillRule="evenodd" transform="translate(1 -50)">
                    <path d={sunPaths[0]} />
                    <path d={sunPaths[1]} fill="#E3D927" />
                    <path d={sunPaths[2]} />
                    <path d={sunPaths[3]} fill="#E3D927" />
                  </g>
                </svg>
              ) : (
                <svg
                  className={`hidden md:block fill-current w-8 h-8 text-white focus:text-white active:text-white hover:text-gray-400`}
                  viewBox="0 0 400 400"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Light Button Night</title>
                  <g fillRule="evenodd">
                    <path d={moonPaths[0]} fill="#E3D927" />
                    <path d={moonPaths[1]} />
                    <path d={moonPaths[2]} />
                    <path d={moonPaths[3]} fill="#E3D927" />
                    <path d={moonPaths[4]} fill="#E3D927" />
                  </g>
                </svg>
              )}
            </button>
          )}
        </ThemeToggler>
      </div>
    </nav>
  )
}

export { SubNavbar }
