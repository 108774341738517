export const formatExcerpt = (html) => {
  let p1 = ''
  let p2 = ''

  const excerpt = html + ' '

  if (excerpt.includes('។')) {
    const texts = excerpt.split('។ ')
    for (let i = 0; i < texts.length; i++) {
      if (i < 2) {
        p1 += texts[i] + '។ '
      } else if (i !== texts.length - 1) {
        p2 += texts[i] + '។ '
      } else {
        p2 += texts[i]
      }
    }
  } else {
    const texts = excerpt.split('. ')
    for (let i = 0; i < texts.length; i++) {
      if (i < 2) {
        p1 += texts[i] + '. '
      } else if (i !== texts.length - 1) {
        p2 += texts[i] + '. '
      } else {
        p2 += texts[i]
      }
    }
  }

  return { p1, p2 }
}
