import React from 'react'
import { Link } from 'gatsby'

const FooterMd = ({ styleV }) => {
  return (
    <div
      className={`${styleV} flex-col justify-center md:justify-between w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg mt-4`}
    >
      <div className="flex flex-row justify-between mb-6">
        <div
          className="hidden md:flex flex-col justify-center md:justify-between"
          style={{ color: 'var(--textNormal)' }}
        >
          <div className="flex flex-col justify-between">
            <Link
              to="/"
              className="shadow-none text-md hover:text-red-400 mb-1"
            >
              ដើមទំព័រ
            </Link>
            <Link
              to="/about"
              className="shadow-none text-md hover:text-red-400 mb-1"
            >
              អំពីដាសេវេនី
            </Link>
            <Link
              to="/blogs/1"
              className="shadow-none text-md hover:text-red-400 mb-1"
            >
              ប្លក់
            </Link>
            <Link
              to="/programmings/1"
              className="shadow-none text-md hover:text-red-400 mb-1"
            >
              ប្រូក្រាមីង
            </Link>
            <Link
              to="/technologies/1"
              className="shadow-none text-md hover:text-red-400 mb-1"
            >
              បច្ចេកវិទ្យា
            </Link>
          </div>
        </div>

        <div className="hidden md:flex flex-col justify-start items-center md:items-start mb-8 md:mb-0">
          <div style={{ color: 'var(--textNormal)' }}>
            <div className="text-md mb-3">ផ្សេងៗ</div>
            <div className="flex text-sm mb-1">
              <Link
                to="/privacy-policy"
                className="shadow-none hover:text-red-400 text-sm"
              >
                <span
                  className="text-sm mr-1"
                  role="img"
                  aria-label="privacy policy"
                >
                  🗄
                </span>
                <span className="text-sm">គោលការណ៍​ឯក​ជន​ភាព</span>
              </Link>
            </div>
            <div className="flex text-sm mb-4">
              <Link
                to="/terms-conditions"
                className="shadow-none hover:text-red-400 text-sm"
              >
                <span
                  className="text-sm mr-1"
                  role="img"
                  aria-label="terms and conditions"
                >
                  📝
                </span>
                <span className="text-sm">លក្ខន្តិកៈការប្រើប្រាស់</span>
              </Link>
            </div>
          </div>
        </div>

        <div
          className="hidden md:flex flex-col justify-between"
          style={{ color: 'var(--textNormal)' }}
        >
          <div>
            <div className="text-md mb-3">តាមដានដាសេវេនី</div>
            <div className="flex flex-col flex-start">
              <a
                className="flex shadow-none mr-2 mb-1 hover:text-red-400"
                href="https://twitter.com/daseveny"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="fill-current text-blue-800 h-5 w-5 mr-2"
                  style={{ color: 'rgb(100, 174, 249)' }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 920 1000"
                >
                  <path d="M920 214c-24 36-55.333 68.667-94 98v24c0 86.667-20 170-60 250S664 733.667 580 789s-180.667 83-290 83c-106.667 0-203.333-28-290-84 9.333 1.333 24.667 2 46 2 88 0 166-26.667 234-80-41.333-1.333-78-14-110-38s-54-55.333-66-94c6.667 2.667 18 4 34 4 17.333 0 34-2 50-6-44-9.333-80-31.333-108-66s-42-74.667-42-120v-2c24 13.333 52 21.333 84 24-56-38.667-84-91.333-84-158 0-32 8.667-63.333 26-94 102.667 125.333 232.667 190.667 390 196-4-12-6-26-6-42 0-52 18.333-96.333 55-133s81.667-55 135-55c54.667 0 100 19.333 136 58 40-8 80-22.667 120-44-13.333 44-40.667 78.667-82 104 37.333-5.333 73.333-15.333 108-30" />
                </svg>
                <span className="text-sm">ថ្វីតទ័រ</span>
              </a>
              <a
                className="flex shadow-none mr-2 mb-1 hover:text-red-400"
                href="https://t.me/daseveny"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  className="fill-current h-5 w-5 mr-2"
                  viewBox="50 40 420 420"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs />
                  <path
                    d="M254.6 76.5c49 .3 90.8 16.4 125.7 49.3 34.8 32.9 53.8 73.7 56.1 121.6 2.4 51.2-14 96-49.5 133.1-34.9 36.5-78 55.3-128.6 55.8-47.1.5-88.4-15.3-123-47.2-40.8-37.7-60.4-84.9-58.3-140.4 1.7-46.5 19.6-86.5 52.5-119.3 34.7-34.7 77.1-52 125.1-52.9z"
                    fill="#0087cb"
                  />
                  <path
                    d="M201.4 280.7c-9.8-3-19.7-6.1-29.5-9.1l-14.4-4.5c-1.8-.6-3.5-1.2-5-2.3-2.8-2.1-3-4.7-.6-7.3 1.6-1.8 3.6-3 5.8-3.9l17.4-6.9c40-15.4 80-30.9 120.1-46.3 12.7-4.9 25.3-9.8 38-14.7 1.7-.7 3.5-1.1 5.4-.8 3.8.6 6.7 3.7 7.1 7.7.2 2.2.1 4.4-.3 6.6-4.9 23-9.8 46.1-14.7 69.1-4 19-8.1 38-12.1 57.1l-2.7 12.9c-.4 2.1-1 4.1-2 6-2 3.7-5.2 5.1-9.3 4.3-2.5-.5-4.7-1.6-6.7-3.1-13.8-10.2-27.6-20.5-41.5-30.7-.6-.5-1.3-.9-1.9-1.3-2.4-.7-4.1-2.5-6-3.9-6.6-4.8-13.2-9.8-19.8-14.6-.5-.4-1-.8-1.4-1.3-.6-1 0-1.6.6-2.2 9-8 17.9-16.2 26.9-24.2 6.5-5.9 13.1-11.8 19.6-17.7 6.5-5.9 13-11.7 19.5-17.5 5.1-4.6 10.2-9.1 15.2-13.7l1.2-1.2c.3-.4.7-.8.5-1.3-.2-.6-.8-.6-1.3-.6-1.8-.2-3.3.5-4.8 1.4-5.5 3.3-10.8 6.8-16.2 10.2-16.4 10.3-32.9 20.7-49.3 31-11.3 7.1-22.6 14.2-33.9 21.4-1.3.7-2.2 2-3.9 1.4z"
                    fill="#fcfdfe"
                  />
                  <path
                    d="M201.4 280.7c2.7-1.1 5-2.9 7.5-4.4 7.2-4.5 14.4-9 21.6-13.6 5.1-3.2 10.1-6.5 15.2-9.7 8.4-5.3 16.9-10.6 25.4-15.9 5.9-3.7 11.8-7.5 17.8-11.3l15.6-9.9c1.7-1 3.4-1.6 5.5-1.4.6.1 1.2.1 1.4.7.3.6.1 1.2-.4 1.8-1.2 1.2-2.3 2.5-3.7 3.6-2.5 2-4.7 4.3-7.1 6.4-3.9 3.3-7.5 6.9-11.4 10.2-1.6 1.4-3.1 2.9-4.7 4.3-2.3 1.9-4.5 4.2-6.7 6.2-3.4 3.1-6.9 6.1-10.3 9.1-1.5 1.4-3.1 2.8-4.5 4.2-3.7 3.5-7.6 6.8-11.3 10.3-4.5 4.1-9.1 8.1-13.5 12.3-3.2 3-6.6 5.8-9.9 8.7-.5.4-.5.8-.2 1.3-.7 11.3-1.5 22.6-2.2 33.9-.2 3-.6 5.9-.5 8.9 0 .9 0 1.7-.7 2.4-.6.2-1.3.2-1.9.2-1.8-.1-3.1-1-3.6-2.7-.5-1.8-1.1-3.5-1.6-5.3-4.9-16.1-9.8-32.3-14.7-48.4-.5-.7-.8-1.3-1.1-1.9z"
                    fill="#ccd9e9"
                  />
                  <path
                    d="M224 338.7c-.3-3.9.4-7.8.5-11.6.2-4.2.6-8.4.8-12.6.3-4.3.6-8.6.9-12.8.1-2.2.3-4.4.3-6.5 0-.7.2-1.2.9-1.6 8.1 6 16.2 11.9 24.2 17.9.9.7 1.9 1.2 2.6 2.1-7.5 7.3-15.1 14.6-22.6 21.9-2 2-4.4 3.6-7.6 3.2z"
                    fill="#b1c8db"
                  />
                </svg>
                <div className="text-sm">តេលេក្រាម</div>
              </a>
              <Link
                className="flex shadow-none mr-2 hover:text-red-400"
                to="/rss.xml"
              >
                <svg
                  className="fill-current h-4 w-5 mr-2"
                  style={{ color: 'rgb(234, 170, 0)' }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 760 1000"
                >
                  <path d="M0 120c209.333 0 388.333 74.333 537 223 148.667 148.667 223 327.667 223 537H642c0-177.333-62.667-328.333-188-453C328.667 302.333 177.333 240 0 240V120m0 238c145.333 0 269 51 371 153s153 225 153 369H406c0-110.667-39.667-205.667-119-285S112 476 0 476V358m114 296c30.667 0 57.333 11 80 33s34 49 34 81c0 30.667-11.333 57-34 79s-49.333 33-80 33-57.333-11-80-33-34-48.333-34-79c0-32 11.333-59 34-81s49.333-33 80-33" />
                </svg>
                <span className="text-sm">ហ្វីដ RSS</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <div
          className="flex text-sm mb-1"
          style={{ color: 'var(--textNormal)' }}
        >
          <span className="text-sm mr-1" role="img" aria-label="copyrights">
            ©️ Daseveny 2020 - {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </div>
  )
}

export { FooterMd }
