import React from 'react'
import { NavbarXs } from './navbar-xs'
import { NavbarMd } from './navbar-md'
import { getColorOfTheDay } from '../../../utils'

const MainNavbar = ({ location }) => {
  return (
    <nav
      className={`flex shadow-md items-center justify-center flex-wrap py-2 md:py-8 mb-1 md:mb-0 w-full bg-${getColorOfTheDay()}`}
    >
      <NavbarXs location={location} styleV="flex md:hidden" />
      <NavbarMd location={location} styleV="hidden md:flex" />
    </nav>
  )
}

export { MainNavbar }
